import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'reschedule-interview-modal',
	templateUrl: './reschedule-interview-modal.component.html',
	styleUrls: ['./reschedule-interview-modal.component.scss']
})
export class RescheduleModalModalComponent implements OnInit {

	@Input() companyName: string = '';
	@Output() reschedule: EventEmitter<any> = new EventEmitter<any>();

	public message: string = '';
	public maxLength: number = 5000;

	constructor(public modal: NgbActiveModal) { }

	ngOnInit() {

	}

	remaining() {
		return this.maxLength - this.message?.length;
	}


	rescheduleInterview() {
		this.reschedule.emit(this.message);
		this.closeThisDialog();
	}

	closeThisDialog() {
		this.modal.dismiss();
	}

}
