import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'invite-modal',
	templateUrl: './invite-modal.component.html',
	styleUrls: ['./invite-modal.component.scss']
})
export class InviteModalComponent implements OnInit {

	@Input() contactList: any[] = [];
	@Output() referContacts: EventEmitter<any> = new EventEmitter<any>();

	public searchText: string = '';

	constructor(public modal: NgbActiveModal) { }

	ngOnInit() {

	}

	selecteAll() {
		this.contactList.forEach((value: any) => {
			value.checked = true;
		});
	}

	DeselecteAll() {
		this.contactList.forEach((value: any) => {
			value.checked = false;
		});
	}

	referCandidates() {
		this.referContacts.emit();
		this.closeThisDialog();
	}

	closeThisDialog() {
		this.modal.dismiss();
	}

}
