<div class="modal-v2">
	<div class="modal-header">
		<h1 class="modal-title" id="modal-title">Take photo</h1>
		<button type="button" class="close" aria-label="Close" (click)="close()">
		</button>
	</div>
	<div class="modal-body">
		<div class="camera-feed-wrapper">
			<div class="photo-preview-wrapper" [hidden]="showCaptureButton">
				<canvas id="imagePreview"></canvas>
			</div>
			<camera></camera>
		</div>
	</div>
	<div class="modal-footer text-right">
		<button (click)="capturePhoto()" [hidden]="!showCaptureButton" type="button" class="btn record-video-button">
			Take Photo
		</button>
		<div [hidden]="showCaptureButton">
			<div class="retake-buttons d-flex justify-content-between">
				<button (click)="retakePhoto()" type="button" class="btn retake-video-button">Retake</button>
				<button (click)="savePhoto()" type="button" class="btn save-video-button">Save</button>
			</div>
		</div>
	</div>
</div>
