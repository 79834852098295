import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SocketIOService } from './socket-io.service';
import * as moment from 'moment';

@Injectable()
export class InterviewService {
	private serverUrl = environment.urls.messageUrl_Obj;

	constructor(private http: HttpClient,
		private socketIOService: SocketIOService,
		private cookieService: CookieService) { }

	// get Interview data
	getInterviews() {
		return this.http.get(`${this.serverUrl}interviews/recruit/get`);
	}

	interviewAcepted(contentId: any, interviewId: any, slotId: any) {
		let data: any = {
			interview_content_id: contentId,
			selected_slot_id: slotId
		}
		return this.http.post(`${this.serverUrl}interviews/recruit/accept`, data).pipe(
			map((response: any) => {
				// call sync view to refresh the page
				if (response.Code === 200) {
					this.socketIOService.syncViewStatus({ viewNumber: 2, interview_id: interviewId, user_id: Number(this.cookieService.get('user_id')) })
				}
				return response;
			})
		);
	}

	rejectInterview(contentId: any, interviewId: any) {
		let data: any = {
			interview_content_id: contentId
		}
		return this.http.post(`${this.serverUrl}interviews/recruit/reject`, data).pipe(
			map((response: any) => {
				// call sync view to refresh the page
				if (response.Code === 200) {
					this.socketIOService.syncViewStatus({ viewNumber: 2, interview_id: interviewId, user_id: Number(this.cookieService.get('user_id')) })
				}
				return response;
			})
		);
	}

	resheduleInterview(contentId: any, interviewId: any, value: any) {
		let data: any = {
			interview_content_id: contentId,
			reschedule_message: value
		}
		return this.http.post(`${this.serverUrl}interviews/recruit/reschedule`, data).pipe(
			map((response: any) => {
				// call sync view to refresh the page
				if (response.Code === 200) {
					this.socketIOService.syncViewStatus({ viewNumber: 2, interview_id: interviewId, user_id: Number(this.cookieService.get('user_id')) })
				}
				return response;
			})
		);
	}

	getZoomUrl(contentId: any) {
		return this.http.get(`${this.serverUrl}interviews/getInterviewUrl/${contentId}`);
	}

	getTimeZoneList() {
		// check current date to get corect timezone data list
		let timeZoneJson = '';
		let secondSunMarch = new Date(new Date().getFullYear(), 2, 7 + (7 - new Date(new Date().getFullYear(), 2, 7).getDay()));
		let firstSunNov = new Date(new Date().getFullYear(), 10, (7 - new Date(new Date().getFullYear(), 2, 0).getDay()));
		let currentDate = moment(moment(new Date()).format('DD/MM/YYYY'), 'DD/MM/YYYY');

		let startDate = moment(moment(secondSunMarch).format('DD/MM/YYYY'), 'DD/MM/YYYY');
		let endDate = moment(moment(firstSunNov).format('DD/MM/YYYY'), 'DD/MM/YYYY');
		if (startDate <= currentDate && currentDate <= endDate) {
			timeZoneJson = '../../../asset/data/timezones_daylight.json';
		} else {
			timeZoneJson = '../../../asset/data/timezones_standard.json';
		}
		return this.http.get(timeZoneJson);
	}

}
