import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class MessageService {
	private serverUrl = environment.urls.messageUrl_Obj;

	constructor(private http: HttpClient) { }

	// get conversations
	getConversations() {
		return this.http.get(`${this.serverUrl}message/conversation`);
	}

	// get message
	getMessage(conId: any, lastTimestamp: any, messageCount: any) {
		return this.http.get(`${this.serverUrl}message/conversation/${conId}/?last_timestamp=${lastTimestamp}&message_count=${messageCount}`);
	}


	// mark as read
	markAsRead(conId: any) {
		let data: any = {
			conversation_id: conId
		}
		return this.http.post(`${this.serverUrl}message/read`, data);
	}

	// delete Message
	deactiveMessage(messageId: any) {
		return this.http.post(`${this.serverUrl}message/profilesender/${messageId}/delete`, {});
	}

	// set Archive
	setArchive(conId: any, isArchive: boolean) {
		let data: any = {
			conversation_id: conId,
			archive: isArchive
		}
		return this.http.post(`${this.serverUrl}message/conversation/archive`, data);
	}

	// file upload
	uploadAttachment(file: any) {

		let fd = new FormData();
		fd.append('uploadedFile', file);
		if (file.type === 'application/pdf') {
			fd.append('FileType', 'PDF')
		} else if (file.type.substring(0, 5) === 'image') {
			fd.append('FileType', 'IMAGE')
		} else if (file.type.substring(0, 5) === 'video') {
			fd.append('FileType', 'VIDEO')
		} else if (file.type.substring(0, 5) === 'audio') {
			fd.append('FileType', 'AUDIO')
		} else {
			fd.append('FileType', 'OTHERS')
		}

		return this.http.post(file.uploadUrl, fd);
	}

}
