<div class="modal-header">
	<h4 class="modal-title" id="modal-title">Remove Photo?</h4>
</div>
<div class="modal-body">
	<div>Are you sure you want to remove this photo?</div>
</div>
<div class="modal-footer text-right">
	<div class="button-wrapper">
		<button type="button" class="btn no-button" (click)="cancel()">Cancel</button>
		<button type="button" class="btn yes-button" (click)="confirm()">Confirm</button>
	</div>
</div>
