import { Injectable } from '@angular/core';

@Injectable()
export class ProfileDataService {
	public showDashboard: boolean = false;

	public profileDataLoaded: boolean = false;
	public verificationStatusLoaded: boolean = false;
	public verifyStatus: string = '';

	public allProfileData: any = {};
	public BasicInformation: any = {};

	public editResumeId: number = 0;

	constructor() {

	}

	getShowDashboard() {
		return this.showDashboard;
	}

	getProfileDataLoaded() {
		return this.profileDataLoaded;
	}

	getVerificationStatusLoaded() {
		return this.verificationStatusLoaded;
	}

	getVerifyStatus() {
		return this.verifyStatus;
	}

	getAllProfileData() {
		return this.allProfileData;
	}

	getBasicInformation() {
		return this.BasicInformation;
	}

	getEditResumeId() {
		return this.editResumeId;
	}

	setShowDashboard(data: boolean) {
		this.showDashboard = data;
	}

	setProfileDataLoaded(data: boolean) {
		this.profileDataLoaded = data;
	}

	setVerificationStatusLoaded(data: boolean) {
		this.verificationStatusLoaded = data;
	}

	setVerifyStatus(data: string) {
		this.verifyStatus = data;
	}

	setAllProfileData(data: any) {
		this.allProfileData = data;
	}

	setBasicInformation(data: any) {
		this.BasicInformation = data;
	}

	setEditResumeId(data: number) {
		this.editResumeId = data;
	}

	clearProfileData() {
		this.showDashboard = false;
		this.profileDataLoaded = false;
		this.verificationStatusLoaded = false;
		this.verifyStatus = '';
		this.allProfileData = {};
		this.BasicInformation = {};
		this.editResumeId = 0;
	}

}
