import { Directive, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';

@Directive({
	selector: '[autoExpandText]'
})
export class AutoExpandTextDirective implements OnInit {
	constructor(public renderer: Renderer2, public elmRef: ElementRef) {

	}

	ngOnInit() {
		this.renderer.addClass(this.elmRef.nativeElement, 'auto-expand-input');
		setTimeout(() => {
			if (this.elmRef.nativeElement.scrollHeight > 35) {
				this.elmRef.nativeElement.height(this.elmRef.nativeElement.scrollHeight);
			}
		}, 0);
	}

	@HostListener('keydown', ['$event']) onKeydown($event: any) {
		this.elmRef.nativeElement.style.cssText = 'height:auto; padding:0';
		this.elmRef.nativeElement.style.cssText = 'height:' + this.elmRef.nativeElement.scrollHeight + 'px';
	}

}
