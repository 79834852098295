import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class OfferService {
	private serverUrl = environment.urls.messageUrl_Obj;

	constructor(private http: HttpClient) { }

	// get Offer data
	getOffers() {
		return this.http.get(`${this.serverUrl}offers/recruit/get`);
	}

	getOffersById(offerContentID: any) {
		return this.http.get(`${this.serverUrl}offers/recruit/getDetails/${offerContentID}`);
	}

	offerAccepted(offerId: any) {
		return this.http.post(`${this.serverUrl}offers/recruit/accept/${offerId}`, {});
	}

	rejectOffer(offerId: any) {
		return this.http.post(`${this.serverUrl}offers/recruit/decline/${offerId}`, {});
	}

	// file upload
	uploadAttachment(file: any) {
		let fd = new FormData();
		fd.append('uploaded_file', file);
		if (file.type === 'application/pdf') {
			fd.append('file_type', 'PDF');
		} else {
			fd.append('file_type', 'DOC');
		}
		fd.append('guid', '');
		fd.append('cell_id', '');

		return this.http.post(file.uploadUrl, fd);
	}

	sendResponse(attachment: any) {
		return this.http.post(`${this.serverUrl}offers/recruit/send/response/attachment`, attachment);
	}

}
