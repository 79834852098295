import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmAlertComponent } from '../../shared/components/confirm-alert/confirm-alert.component';
import { environment } from 'src/environments/environment';
import { JobBoardRecruitService } from '../../core/services/jobBoardRecruit.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
	selector: 'app-header-default',
	templateUrl: './default-header.component.html',
	styleUrls: ['./default-header.component.scss']
})
export class DefaultHeaderComponent implements OnInit {

	public isSigningIn: boolean = false;
	public hireUrl = environment.urls.hire_url_obj;

	public showIncompleteProfileBar: boolean = false;
	public fileServerUrlHire: string = environment.urls.fileServerUrlHire_Obj;
	public selectedJobData: any = {};

	constructor(private cookieService: CookieService,
		private jobBoardRecruitService: JobBoardRecruitService,
		private modalService: NgbModal,
		private userService: UserService) { }

	ngOnInit(): void {

		if (this.cookieService.check('_jobboard')) {
			this.jobBoardRecruitService.getSelectedJobData(this.cookieService.get('_jobboard'))
				.subscribe((response: any) => {
					console.log('call job board service to get job details about given jobid');
					console.log(response);
					if (response.Code === 200) {
						this.selectedJobData = response.Data;
						this.showIncompleteProfileBar = true;
					} else {
						// link broken
						// this.router.navigateByUrl('/');
					}
				});
		}

	}

	signOut() {
		if (this.cookieService.check('_jobboard')) {
			this.logoutAndCancelApplication();
		} else {
			this.signOutFromUnnanu();
		}
	}

	private logoutAndCancelApplication() {
		let modal = this.modalService.open(ConfirmAlertComponent, { windowClass: 'alert-pop-up-style-v2', backdrop: 'static', keyboard: false });
		modal.componentInstance.header = 'Cancel application';
		modal.componentInstance.msg = 'Are you sure you want to cancel now?';
		modal.result.then((closed) => {
			this.signOutFromUnnanu();
		},
			(dismissed) => {
				console.log('Rejected cancel application');
			});
	}

	private signOutFromUnnanu() {
		this.userService.signOutFromUnnanu().subscribe();
	}

	cancelApplication() {
		let modal = this.modalService.open(ConfirmAlertComponent, { windowClass: 'alert-pop-up-style-v2', backdrop: 'static', keyboard: false });
		modal.componentInstance.header = 'Cancel application';
		modal.componentInstance.msg = `You're almost done with your application. Are you sure you want to cancel now?`;
		modal.result.then((closed) => {
			this.cookieService.delete('_jobboard', '/');
			this.showIncompleteProfileBar = false;
			console.dir(closed);
			this.continueSignup();
		},
			(dismissed) => {
				console.log('Rejected cancel application');
			});
	}

	private continueSignup() {
		let modal = this.modalService.open(ConfirmAlertComponent, { windowClass: 'alert-pop-up-style-v2', backdrop: 'static', keyboard: false });
		modal.componentInstance.header = 'Application canceled';
		modal.componentInstance.msg = 'Your application has been canceled. Do you still want to continue with the sign up?';
		modal.result.then((closed) => {
			this.signOut();
			console.dir(closed);
		},
			(dismissed) => {
				console.log('Rejected cancel application');
			});
	}

}
