<div class="modal-header">
	<h4 class="modal-title" id="modal-title">{{header}}</h4>
</div>
<div class="modal-body">
	<div>{{msg}}</div>
</div>
<div class="modal-footer text-right">
	<div class="button-wrapper">
		<button type="button" class="btn no-button" (click)="no()">No</button>
		<button type="button" class="btn yes-button" (click)="yes()">Yes</button>
	</div>
</div>
