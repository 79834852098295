import * as io from 'socket.io-client';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EventService } from './event.service';
import { CookieService } from 'ngx-cookie-service';

type SocketIOCallback = (data: any) => void;

@Injectable()
export class SocketIOService {

	private socketServerURL = environment.urls.socketServerURL;

	private socket: any;

	constructor(private eventService: EventService,
		private cookieService: CookieService) {
		this.socket = io(this.socketServerURL);
	}

	on(eventName: string, callback: SocketIOCallback) {
		if (this.socket !== undefined) {
			this.socket.on(eventName, callback);
		}
	}

	emit(eventName: string, data: any) {
		if (this.socket !== undefined) {
			this.socket.emit(eventName, data);
		}
	}

	connect() {
		if (this.socket && this.socket.connected === false) {
			let tokenData: any = {};
			tokenData.AuthToken = this.cookieService.get('token');
			tokenData.UserType = '1';
			this.socket.emit('AuthorizationToken', tokenData);
			this.socket.connected = true;
		} else {
			this.reconnect();
		}

		this.eventService.subscribe('mark-read', (payload: any) => {
			this.socket.emit('MarkAsReadMessage', payload.markedMessage)
		});

		this.socket.on('SuccessMarkAsReadMsg', (data: any) => {
			console.log('SuccessMarkAsReadMsg');
			console.log(data);
		});

		this.socket.on('InterviewAccepted', (data: any) => {
			console.log('InterviewAccepted');
			console.log(data);
		});

		// exception found
		this.socket.on('Exception', (data: any) => {
			console.log('Exception');
			console.log(data);
		});

		// disconnect status
		this.socket.on('disconnect', (data: any) => {
			console.log('Disconnect');
			console.log(data);
		});

		// listenig the sync view
		this.socket.on('SyncViewUpdate', (data: any) => {
			console.log('SyncViewUpdate');
			console.log(data);
		});

		// listenig the connect
		this.socket.on('connect', (data: any) => {
			console.log('connect');
			console.log(this.socket.id);
		});

	}

	reconnect() {
		this.socket = io(this.socketServerURL, {
			forceNew: true
		});
		let tokenData: any = {};
		tokenData.AuthToken = this.cookieService.get('token');
		tokenData.UserType = '1';
		this.socket.emit('AuthorizationToken', tokenData);
	}

	disconnect() {
		if (this.socket !== undefined && this.socket.connected) {
			this.socket.close();
			this.socket = undefined;
		}
	}

	sendApplication(applicationData: any) {
		if (this.socket !== undefined) {
			if (this.socket.connected) {
				this.socket.emit('JobApplied', applicationData);
			}
		}
	}

	sendMessage(messageData: any) {
		if (this.socket !== undefined) {
			if (this.socket.connected) {
				this.socket.emit('SendMessage', messageData);
			}
		}
	}

	interviewMarkAsRead(interviewData: any, recruitId: any) {
		if (this.socket !== undefined) {
			let tokenData: any = {};
			tokenData.token = this.cookieService.get('token');
			tokenData.userType = '1';
			tokenData.userId = recruitId;
			tokenData.interview_content_id = interviewData;
			this.socket.emit('InterviewMarkAsRead', tokenData);
			this.eventService.broadcast('interviewMarkAsRead', interviewData);
		}
	}

	acceptInterview(acceptedInterviewData: any) {
		if (this.socket !== undefined) {
			this.socket.emit('AcceptInterview', acceptedInterviewData);
		}
	}

	declineInterview(interviewData: any) {
		if (this.socket !== undefined) {
			this.socket.emit('DeclineInterview', interviewData);
			console.log(interviewData);
		}
	}

	requestRescheduleInterview(interviewData: any) {
		if (this.socket !== undefined) {
			this.socket.emit('RequestRescheduleInterview', interviewData);
			console.log(interviewData);
		}
	}

	syncViewStatus(syncViewData: any) {
		if (this.socket !== undefined) {
			this.socket.emit('SyncView', syncViewData);
			console.log(syncViewData);
		}
	}

	getConnectionStatus() {
		return this.socket ? this.socket.connected : this.socket;
	}

	removeListener(name: any) {
		if (this.socket !== undefined && this.socket.connected) {
			this.socket.removeListener(name);
		}
	}

	offerMarkAsRead(offerData: any, recruitId: any) {
		if (this.socket !== undefined) {
			let tokenData: any = {};
			tokenData.token = this.cookieService.get('token');
			tokenData.userType = '1';
			tokenData.userId = recruitId;
			tokenData.offer_content_id = offerData;
			this.socket.emit('OfferMarkAsRead', tokenData);
			this.eventService.broadcast('offerMarkAsRead', offerData);
		}
	}

	AcceptOffer(hireUserId: any, offerContentId: any) {
		if (this.socket !== undefined) {
			let tokenData: any = {};
			tokenData.hire_user_id = hireUserId
			tokenData.offer_content_id = offerContentId
			this.socket.emit('AcceptOffer', tokenData);
		}
	}

	DeclineOffer(hireUserId: any, offerContentId: any) {
		if (this.socket !== undefined) {
			let tokenData: any = {};
			tokenData.hire_user_id = hireUserId
			tokenData.offer_content_id = offerContentId
			this.socket.emit('DeclineOffer', tokenData);
		}
	}

	OfferResponseAttachment(hireUserId: any, offerContentId: any) {
		if (this.socket !== undefined) {
			let tokenData: any = {};
			tokenData.hire_user_id = hireUserId
			tokenData.offer_content_id = offerContentId
			this.socket.emit('OfferResponseAttachment', tokenData);
		}
	}

}
