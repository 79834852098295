<div class="modal-header">
	<h4 class="modal-title" id="modal-title"></h4>
</div>
<div class="modal-body">
	<div>
		<iframe [src]="url" style="width:100%; height:575px;" frameborder="0"></iframe>
	</div>
</div>
<div class="modal-footer text-right">
</div>
