import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventService } from '../../../core/services/event.service';
import { AutocompleteService } from '../../../core/services/autocomplete.service';
import { Observable, of, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { VideoRecordModalComponent } from '../video-record-modal/video-record-modal.component';
import { environment } from 'src/environments/environment';
import { UtilityService } from '../../../core/services/utility.service';

@Component({
	selector: 'add-certification-modal',
	templateUrl: './add-certification-modal.component.html',
	styleUrls: ['./add-certification-modal.component.scss']
})
export class AddCertificationModalComponent implements OnInit, AfterViewInit {

	@Input() data: any = {};
	@Input() id: any;
	@Input() delete: boolean = false;
	@Input() pageStatus: any;
	@Input() ctrl: any;
	@Output() deleteFn: EventEmitter<any> = new EventEmitter<any>();
	@Output() certificateEditFn: EventEmitter<any> = new EventEmitter<any>();
	@Output() certificateAddFn: EventEmitter<any> = new EventEmitter<any>();
	@Output() certificateEditFnGs: EventEmitter<any> = new EventEmitter<any>();
	@Output() certificateAddFnGs: EventEmitter<any> = new EventEmitter<any>();


	public fileServerUrl: string = environment.urls.fileServerUrl_Obj;

	// dropdown toggles
	private companySelected: boolean = false;
	private videoData: any = null;
	private oldData: any = null;
	private oldDataGT: any = null;
	private inGetStartedController: any = null;

	public companyErrorMessage: string = 'Enter certifying authority';
	public showCompanyError: boolean = false;
	public suggestionsAuthotity: any[] = [];
	public hasToShowCompanySuggestions: boolean = false;
	public showCompanyAddtolist: any;
	public showCertificationNameError: boolean = false;
	public certificationNameErrorMessage: string = '';
	public showLicenceNumberError: boolean = false;
	public LicenceNumberErrorMessage: string = '';
	public showUrlError: boolean = false;
	public startDateError: boolean = false;
	public endDateError: boolean = false;
	public timePeriodError: boolean = false;
	public startDateErrorMessage: string = '';
	public endDateErrorMessage: string = '';
	public timePeriodErrorMessage: string = '';

	public muteClassAdd: boolean = true;
	public startYear: any = 'Year:';
	public startMonth: any = 'Month:';
	public endYear: any = 'Year:';
	public endMonth: any = 'Month:';
	public certificationName: string = '';
	public licenceNumber: string = '';
	public company: string = '';
	public isCurrentlyWorking: boolean = false;
	public videoDeleted: boolean = false;
	public companyId: any;
	public companyUrl: any;
	public notes: any;
	public selectedVideoUrl: any;
	public videoSelected: any;
	public maxLength: number = 2000;
	public hasVideoStatus: any;
	public videoEditted: boolean = false;
	public rawVideoUrl: any;
	public photoSelected: boolean = false;
	public selectedPhoto: any;

	public currentYear = new Date().getFullYear();
	public maximumYear = this.currentYear + 20;

	public valueUpdated: boolean = false;

	constructor(public modal: NgbActiveModal,
		private sanitizer: DomSanitizer,
		private autocompleteService: AutocompleteService,
		private eventService: EventService,
		private modalService: NgbModal,
		private utilityService: UtilityService) { }

	ngOnInit() {
		// condition if edit on get started page
		if (this.id && this.data) {
			this.inGetStartedController = true;
			let data = this.data;
			this.oldDataGT = this.data;
			this.id = data.id;
			this.certificationName = data.certificateName;
			this.company = data.authority;
			this.companyId = data.authorityId;
			this.companyUrl = data.autorityUrl;
			this.licenceNumber = data.licenceNumber;
			this.notes = data.notes;
			this.isCurrentlyWorking = data.doesExpire;
			this.startMonth = data.startMonth;
			this.startYear = data.startYear;
			this.endMonth = data.endMonth;
			this.endYear = data.endYear;
			this.videoData = data.videoUrl;
			this.selectedVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data.videoUrl);
			this.videoSelected = !((data.videoUrl.toString() === '' || data.videoUrl.toString() === this.fileServerUrl));
		}

		// condition if on profile page
		if (this.data && this.data.COGUID) {
			this.inGetStartedController = false;
			let data = this.data;
			console.log(data);
			this.oldData = this.data;
			this.id = data.Id;
			this.certificationName = data.Title;
			this.company = data.Authority.Name;
			this.companyId = data.Authority.Id;
			this.companyUrl = data.Authority.LogoUrl;
			this.licenceNumber = data.LicenceNumber;
			this.notes = data.Notes || '';
			this.isCurrentlyWorking = (data.EndDate === 0);
			this.startMonth = 'Month:';
			this.startYear = data.StartDate.toString();
			this.endMonth = 'Month:';
			this.endYear = data.EndDate.toString();
			this.hasVideoStatus = data.hasVideo;
			this.videoData = undefined;
			this.videoSelected = !((data.VideoUrl.toString() === '' || data.VideoUrl.toString() === this.fileServerUrl));
			this.selectedVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data.VideoUrl.toString());
		}

		this.eventService.subscribe('video-selected', (value) => {
			// video selected
			this.muteClassAdd = true;
			this.videoEditted = true;
			this.videoSelected = true;
			this.selectedVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(value.data.url);
			this.videoData = value.data.videoData;
			this.rawVideoUrl = value.data.url;
			if (document.getElementById('muteBtn')) {
				this.muted(true);
			}
			this.valueUpdated = true;
		});

	}

	ngAfterViewInit() {
		this.muted(true);
	}

	remaining() {
		return this.maxLength - document.getElementsByTagName('textarea')[0].value.length;
	}

	muted(val: boolean) {
		if (val !== undefined && val !== null) {
			(document.getElementById('vidPreview') as HTMLMediaElement).muted = val;
			this.muteClassAdd = val;
		}
	}

	recordVideo() {
		this.muteClassAdd = true;
		this.muted(true);
		let modal = this.modalService.open(VideoRecordModalComponent,
			{ windowClass: 'modal-theme-unnanu-v2 video-recorder-popup', backdrop: 'static', keyboard: false });
	}

	positionVideo() {
		let selectedVideo = (document.querySelector('#vidPreview') as HTMLElement);
		let leftMargin = ((selectedVideo.offsetWidth - selectedVideo.offsetHeight) / 2) * -1;
		(document.getElementById('vidPreview') as HTMLElement).style.marginLeft = `${leftMargin}px`;
		if (!this.videoEditted) {
			this.muted(true);
		}
	}


	suggestCompanies() {
		this.validateCompany();
		this.companySelected = false;
		if (this.company) {
			if (this.company.length >= 3) {
				return this.autocompleteService.get(this.company, 'company').pipe(map((response: any) => {
					if (
						response.status === 200 &&
						response.Code === 200 &&
						response.Data.length > 0
					) {
						this.suggestionsAuthotity = response.Data;
						this.hasToShowCompanySuggestions = true;
						return this.suggestionsAuthotity;
					} else {
						// Add New Position
						this.suggestionsAuthotity = [];
						this.hasToShowCompanySuggestions = false;
						return this.suggestionsAuthotity;
					}
				})
				);
			} else {
				this.hasToShowCompanySuggestions = false;
				return of([]);
			}
		} else {
			return of([]);
		}
	}

	search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
		text$.pipe(
			debounceTime(200),
			distinctUntilChanged(),
			switchMap(term => this.suggestCompanies())
		)

	companySelectedFn(event: any) {
		event.preventDefault();
		let companyName = event.item.Name;
		let id = event.item.AuthorityId;
		let url = event.item.LogoUrl;
		if (companyName !== '') {
			this.company = companyName;
			this.companyId = id;
			this.companyUrl = url;
			this.hasToShowCompanySuggestions = false;
			this.companySelected = true;
			this.showCompanyAddtolist = false;
		} else {
			this.company = ''
			this.showCompanyAddtolist = false;
		}
	}

	hideCompanySuggestions() {
		this.validateCompany()
		this.hasToShowCompanySuggestions = false;
		if (!this.companySelected) {
			if (!this.inGetStartedController && this.oldData) {
				if (this.oldData.Authority.Name !== this.company) {
					// this.company = '';
				}
			} else {
				// this.company = '';
				if (this.inGetStartedController && this.oldDataGT) {
					if (this.oldDataGT.authority !== this.company) {
						// this.company = '';
					}
				} else {
					// this.company = '';
				}
			}
			this.validateCompany();
		}
	}

	validateCompany() {
		if (this.company === '') {
			this.companyErrorMessage = 'Enter certifying authority';
			this.showCompanyError = true;
		} else {
			this.showCompanyError = false;
		}
	}

	deleteData() {
		if (this.delete) {
			this.deleteFn.emit(this.data.COGUID);
			this.close();
		}
	}

	saveData() {
		this.validateTimePeriod();
		this.validateCertificationName();
		this.validateLicenceNumber();
		this.validateCompany();

		if ((this.showCertificationNameError || this.showLicenceNumberError || this.showUrlError || this.startDateError || this.endDateError || this.timePeriodError)) {
			// invalid
		} else {
			// valid
			let guid;
			if (this.data.id) {
				guid = this.id;
			} else if (this.data && this.data.COGUID) {
				guid = this.data.COGUID;
			} else {
				guid = this.utilityService.genGuid();
			}
			let certificateObject = {
				key: guid,
				value: {
					id: this.id,
					certificateName: this.certificationName,
					authority: this.company,
					authorityId: this.companyId,
					autorityUrl: this.companyUrl,
					licenceNumber: this.licenceNumber,
					notes: this.notes,
					doesExpire: this.isCurrentlyWorking,
					startMonth: this.startMonth,
					startYear: this.startYear,
					endMonth: this.endMonth,
					endYear: (this.isCurrentlyWorking) ? 0 : this.endYear,
					videoData: this.videoData,
					videoUrl: (this.selectedVideoUrl) ? this.selectedVideoUrl.toString() : '',
					isSaved: false
				}
			}

			if (this.id || (this.data && this.data.COGUID)) {
				if (this.pageStatus === 1) {
					this.certificateEditFn.emit({ data: certificateObject, videoEditted: this.videoEditted, ctrl: this.ctrl, videoDeleted: this.videoDeleted });
				} else {
					this.certificateEditFnGs.emit({ data: certificateObject, videoEditted: this.videoEditted, ctrl: this.ctrl, videoDeleted: this.videoDeleted });
				}
			} else {
				if (this.pageStatus === 1) {
					this.certificateAddFn.emit({ data: certificateObject, ctrl: this.ctrl, videoEditted: this.videoEditted });
				} else {
					this.certificateAddFnGs.emit({ data: certificateObject, ctrl: this.ctrl, videoEditted: this.videoEditted });
				}
			}
			this.close();
		}
	}

	validateCertificationName() {
		if (this.certificationName === '') {
			this.certificationNameErrorMessage = 'Enter talent name';
			this.showCertificationNameError = true;
		} else {
			this.showCertificationNameError = false;
		}
	}

	validateLicenceNumber() {
		if (this.licenceNumber === '') {
			this.LicenceNumberErrorMessage = 'Enter talent id';
			this.showLicenceNumberError = true;
		} else {
			this.showLicenceNumberError = false;
		}
	}

	currentlyWorking() {
		this.endYear = 'Year:';
		this.endMonth = { id: '0' };
	}

	// time period field validation
	private validateTimePeriod() {
		if (this.startYear === 'Year:') {
			this.startDateErrorMessage = 'Select year';
			this.startDateError = true;
		} else {
			this.startDateError = false;
		}

		if (this.endYear === 'Year:') {
			this.endDateErrorMessage = 'Select year'
			this.endDateError = true
		} else {
			this.endDateError = false
		}

		if (Number(this.endYear) !== 0 && Number(this.startYear) > Number(this.endYear)) {
			this.timePeriodErrorMessage = 'Talent year ending cannot be before start year';
			this.timePeriodError = true;
		} else {
			if (Number(this.startYear) === Number(this.endYear)) {
				this.timePeriodErrorMessage = 'Talent year ending cannot be before start year';
				this.timePeriodError = true;
			} else {
				this.timePeriodError = false;
			}
		}
		if (this.isCurrentlyWorking) {
			this.endDateError = false;
		}
	}

	removeVideo() {
		this.videoEditted = false;
		this.videoDeleted = true;
		this.videoSelected = false;
		this.selectedVideoUrl = undefined;
		this.videoData = undefined;
		if (document.getElementById('vidPreview')) {
			(document.getElementById('vidPreview') as HTMLMediaElement).muted = true
		}
	}

	getArray(n: number) {
		return new Array(n)
	}

	onChange(event: any){
		this.valueUpdated = true;
	}

	close() {
		this.modal.dismiss();
	}

}
