import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventService } from '../../../core/services/event.service';
import { AutocompleteService } from '../../../core/services/autocomplete.service';
import { Observable, of, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UtilityService } from '../../../core/services/utility.service';
import { ThirtySecondsVideoRecordModalComponent } from '../thirty-seconds-video-record-modal/thirty-seconds-video-record-modal.component';

@Component({
	selector: 'add-education-modal',
	templateUrl: './add-education-modal.component.html',
	styleUrls: ['./add-education-modal.component.scss']
})
export class AddEducationModalComponent implements OnInit, AfterViewInit {

	@Input() data: any = {};
	@Input() id: any;
	@Input() delete: boolean = false;
	@Input() pageStatus: any;
	@Input() ctrl: any;
	@Output() deleteFn: EventEmitter<any> = new EventEmitter<any>();
	@Output() educationEditFn: EventEmitter<any> = new EventEmitter<any>();
	@Output() educationAddFn: EventEmitter<any> = new EventEmitter<any>();
	@Output() educationEditFnGs: EventEmitter<any> = new EventEmitter<any>();
	@Output() educationAddFnGs: EventEmitter<any> = new EventEmitter<any>();

	public fileServerUrl: string = environment.urls.fileServerUrl_Obj;

	private videoData: any;
	private schoolSelected: boolean = false;
	private degreeSelected: boolean = false;
	private FOSSelected: boolean = false;
	private citySelected: boolean = false;
	private _location: string = '';
	private oldData: any;
	private oldDataGT: any;
	private inGetStartedController: any;

	public suggest: boolean = false;
	public muteClassAdd: boolean = true;
	public dSelected: boolean = false;
	public schoolEdited: boolean = false;
	public degreeEdited: boolean = false;
	public FOSEdited: boolean = false;
	public gpaEdited: boolean = false;
	public videoEditted: boolean = false;
	public isVideoMuted: boolean = false;
	public videoDeleted: boolean = false;
	public showAddtolist: boolean = false;
	public showDegreeAddtolist: boolean = false;
	public showFosAddtolist: boolean = false;
	public showSchoolAddtolist: boolean = false;

	public location: string = '';
	public notes: any;
	public gpa: number = 0;
	public GPAErrorMessage: string = '';
	public showGPAError: boolean = false;
	public startYear: string = 'Year:';
	public currentYear: any = new Date().getFullYear();
	public maximumYear: any = this.currentYear + 12;
	public endYear = 'Year:';
	public school: string = '';
	public schoolId: any;
	public schoolUrl: string = '';
	public showSchoolError: boolean = false;
	public schoolErrorMessage: string = '';
	public degree: string = '';
	public degreeId: any;
	public showDegreeError: boolean = false;
	public degreeErrorMessage: string = '';
	public fos: string = '';
	public fosId: any;
	public showFOSError: boolean = false;
	public FOSErrorMessage: string = '';
	public city: string = '';
	public cityErrorMessage: string = '';
	public showCityError: boolean = false;
	public endMonth: any = { id: '0' };
	public startMonth: any = { id: '0' };
	public selectedVideoUrl: any;
	public videoSelected: any;
	public rawVideoUrl: any;
	public maxLength: number = 2000;
	public suggestions: any[] = [];
	public hasToShowSchoolSuggestions: boolean = false;
	public suggestionsDegree: any[] = [];
	public hasToShowDegreeSuggestions: boolean = false;
	public suggestionsFos: any[] = [];
	public hasToShowFOSSuggestions: boolean = false;
	public startDateError: boolean = false;
	public endDateError: boolean = false;
	public timePeriodError: boolean = false;
	public startDateErrorMessage: string = '';
	public endDateErrorMessage: string = '';
	public timePeriodErrorMessage: string = '';
	public photoSelected: boolean = false;
	public selectedPhoto: any;

	public parentobj: any = {
		city: '',
		showCityError: ''
	};

	public valueUpdated: boolean = false;

	constructor(public modal: NgbActiveModal,
		private sanitizer: DomSanitizer,
		private autocompleteService: AutocompleteService,
		private eventService: EventService,
		private modalService: NgbModal,
		private utilityService: UtilityService) { }

	ngOnInit() {
		this.data.months = [
			{ id: '0', name: 'Month' },
			{ id: '1', name: 'January' },
			{ id: '2', name: 'February' },
			{ id: '3', name: 'March' },
			{ id: '4', name: 'April' },
			{ id: '5', name: 'May' },
			{ id: '6', name: 'June' },
			{ id: '7', name: 'July' },
			{ id: '8', name: 'August' },
			{ id: '9', name: 'September' },
			{ id: '10', name: 'October' },
			{ id: '11', name: 'November' },
			{ id: '12', name: 'December' }
		];
		// condition if edit on get started page
		if (this.id && this.data) {
			this.inGetStartedController = true;
			let data = this.data;
			this.oldDataGT = this.data;
			this.id = data.id;
			this.degree = data.degree;
			this.degreeId = data.degree;
			this.endMonth = (data.endMonth === undefined) ? 'Month:' : { id: data.endMonth };
			this.city = data.Location;
			this.endYear = data.endYear.toString();
			this.fos = data.fos;
			this.fosId = data.fosId;
			this.gpa = data.gpa;
			this.notes = data.notes;
			this.school = data.school;
			this.schoolId = data.school;
			this.schoolUrl = data.schoolUrl;
			this.startMonth = (data.startMonth === undefined) ? 'Month:' : { id: data.startMonth };
			this.startYear = data.startYear.toString();
			this.videoData = data.videoData;
			this.videoSelected = !((data.videoUrl.toString() === '' || data.videoUrl.toString() === this.fileServerUrl));
			this.selectedVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data.videoUrl);
			this.rawVideoUrl = data.videoUrl;
		}

		// condition if on profile page
		if (this.data && this.data.COGUID) {
			this.inGetStartedController = false;
			this.oldData = this.data;
			let data = this.data;
			this.id = data.Id;
			this.degree = data.Degree;
			this.degreeId = data.Degree;
			this.endMonth = (data.EndMonth === undefined) ? 'Month:' : { id: data.EndMonth };

			if (data.Location) {
				if (data.Location.Locality) {
					this._location = data.Location.Locality;
				} else {
					this._location = data.Location;
				}
			}

			this.city = this._location;
			this.location = this._location;
			this.endYear = data.End.toString();
			this.fos = data.FieldOfStudy;
			this.fosId = 0;
			this.gpa = data.GPA;
			this.notes = (data.Notes === undefined) ? '' : data.Notes;
			this.school = data.College.Name;
			this.schoolId = data.College.Name;
			this.schoolUrl = data.College.LogoUrl;
			this.startMonth = (data.StartMonth === undefined) ? 'Month:' : { id: data.StartMonth };
			this.startYear = data.Start.toString();
			this.videoData = undefined;
			this.videoSelected = !((data.VideoUrl.toString() === '' || data.VideoUrl.toString() === this.fileServerUrl));
			this.selectedVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data.VideoUrl.toString());
			this.rawVideoUrl = '';
		}

		this.eventService.subscribe('video-selected', (value) => {
			// video selected
			this.muteClassAdd = true;
			this.videoEditted = true;
			this.videoSelected = true;
			this.selectedVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(value.data.url);
			this.videoData = value.data.videoData;
			this.rawVideoUrl = value.data.url;
			if (document.getElementById('muteBtn')) {
				this.muted(true);
			}
			this.valueUpdated = true;
		});

	}

	ngAfterViewInit() {
		this.muted(true);
	}

	remaining() {
		return this.maxLength - document.getElementsByTagName('textarea')[0].value.length;
	}

	muted(val: boolean) {
		if (document.getElementById('vidPreview')) {
			if (val !== undefined && val !== null) {
				(document.getElementById('vidPreview') as HTMLMediaElement).muted = val;
				this.muteClassAdd = val;
			}
		}
	}

	recordVideo() {
		this.muteClassAdd = true;
		this.muted(true);
		let modal = this.modalService.open(ThirtySecondsVideoRecordModalComponent,
			{ windowClass: 'modal-theme-unnanu-v2 video-recorder-popup', backdrop: 'static', keyboard: false });
	}

	positionVideo() {
		let selectedVideo = (document.querySelector('#vidPreview') as HTMLElement);
		let leftMargin = ((selectedVideo.offsetWidth - selectedVideo.offsetHeight) / 2) * -1;
		(document.getElementById('vidPreview') as HTMLElement).style.marginLeft = `${leftMargin}px`;
		if (!this.videoEditted) {
			this.muted(true);
		}
	}


	suggestSchools() {
		this.validateSchool();
		if (this.school.length >= 3) {
			this.showAddtolist = false;
			return this.autocompleteService.get(this.school, 'college').pipe(map((response: any) => {
				if (
					response.status === 200 &&
					response.Code === 200 &&
					response.Data.length > 0
				) {
					this.suggestions = response.Data;
					this.hasToShowSchoolSuggestions = true;
					return this.suggestions;
				} else {
					// Add New Position
					this.suggestions = [];
					this.hasToShowSchoolSuggestions = false;
					return this.suggestions;
				}
			})
			);
		} else {
			this.hasToShowSchoolSuggestions = false;
			return of([]);
		}
	}

	searchSchool: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
		text$.pipe(
			debounceTime(200),
			distinctUntilChanged(),
			switchMap(term => this.suggestSchools())
		)

	schoolSelectedFn(event: any) {
		event.preventDefault();
		let schoolName = event.item.Name;
		let id = event.item.Id;
		let url = event.item.LogoUrl;
		// do what you want to do
		if (schoolName !== '') {
			this.school = schoolName;
			this.schoolId = id;
			this.schoolUrl = url;
			this.schoolSelected = true;
			this.showSchoolAddtolist = false;
		} else {
			this.school = '';
		}
		this.hasToShowSchoolSuggestions = false;
		this.showAddtolist = false;
		this.showSchoolAddtolist = false;
	}

	hideSchoolSuggestions() {
		this.hasToShowSchoolSuggestions = false;
		if (!this.schoolSelected) {
			if (!this.inGetStartedController && this.oldData) {
				if (this.oldData.Authority.Name !== this.school) {
					this.school = '';
				}
			} else {
				// this.school = '';
				if (this.inGetStartedController && this.oldDataGT) {
					if (this.oldDataGT.authority !== this.school) {
						this.school = '';
					}
				} else {
					this.school = '';
				}
			}
			this.validateSchool();
		}
	}

	validateSchool() {
		if (this.school === '') {
			this.schoolErrorMessage = 'Enter certifying authority';
			this.showSchoolError = true;
		} else {
			this.showSchoolError = false;
		}
	}

	suggestDegrees() {
		this.validateDegree();
		this.degreeSelected = false;
		if (this.degree) {
			if (this.degree.length >= 3) {
				return this.autocompleteService.get(this.degree, 'degree').pipe(map((response: any) => {
					if (
						response.status === 200 &&
						response.Code === 200 &&
						response.Data.length > 0
					) {
						this.suggestionsDegree = response.Data;
						return this.suggestionsDegree;
					} else {
						// Add New Position
						this.suggestionsDegree = [];
						this.hasToShowDegreeSuggestions = false;
						return this.suggestionsDegree;
					}
				})
				);
			} else {
				this.hasToShowDegreeSuggestions = false
				return of([]);
			}
		} else {
			this.hasToShowDegreeSuggestions = false
			return of([]);
		}

	}

	searchDegree: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
		text$.pipe(
			debounceTime(200),
			distinctUntilChanged(),
			switchMap(term => this.suggestDegrees())
		)

	degreeFormatter = (result: any) => result.Name;

	degreeSelectedFn(event: any) {
		event.preventDefault();
		let degreeName = event.item.Name;
		let id = event.item.Id;
		// do what you want to do
		if (degreeName !== '') {
			this.dSelected = true;
			this.suggest = true;
			this.degree = degreeName;
			this.degreeId = id;
			this.degreeSelected = true;
			this.hasToShowDegreeSuggestions = false;
			this.showDegreeAddtolist = false;
		} else {
			this.degree = '';
		}
		this.showDegreeAddtolist = false;
	}

	hideDegreeSuggestions() {
		this.hasToShowDegreeSuggestions = false;
		if (!this.degreeSelected) {
			if (!this.inGetStartedController && this.oldData) {
				if (this.oldData.JobTitle !== this.degree) {
					// this.degree = '';
				}
			} else {
				// this.degree = '';
				if (this.inGetStartedController && this.oldDataGT) {
					if (this.oldDataGT.positionName !== this.degree) {
						// this.degree = '';
					}
				} else {
					// this.degree = '';
				}
			}
			this.validateDegree();
		}
	}

	validateDegree() {
		if (this.degree === '') {
			this.degreeErrorMessage = 'Please select your position';
			this.showDegreeError = true;
		} else {
			this.showDegreeError = false;
		}
	}

	suggestFOSs() {
		this.FOSEdited = true;
		this.FOSSelected = false;
		this.validateFOS();
		if (this.fos) {
			if (this.fos.length >= 3) {
				return this.autocompleteService.get(this.fos, 'course').pipe(map((response: any) => {
					if (
						response.status === 200 &&
						response.Code === 200 &&
						response.Data.length > 0
					) {
						this.suggestionsFos = response.Data;
						this.hasToShowFOSSuggestions = false;
						return this.suggestionsFos;
					} else {
						// Add New Position
						this.suggestionsFos = [];
						this.hasToShowFOSSuggestions = false;
						return this.suggestionsFos;
					}
				})
				);
			} else {
				this.hasToShowFOSSuggestions = false
				return of([]);
			}
		} else {
			this.hasToShowFOSSuggestions = false
			return of([]);
		}

	}

	searchFos: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
		text$.pipe(
			debounceTime(200),
			distinctUntilChanged(),
			switchMap(term => this.suggestFOSs())
		)

	fosFormatter = (result: any) => result.Name;

	fosSelectedFn(event: any) {
		event.preventDefault();
		let fos = event.item.Name;
		let id = event.item.Id;
		if (fos !== '') {
			this.fos = fos;
			this.fosId = id;
			this.FOSSelected = true;
			this.showFosAddtolist = false;
		} else {
			this.fos = '';
		}
		this.hasToShowFOSSuggestions = false;
		this.showFosAddtolist = false;
	}

	hideFOSSuggestions() {
		this.hasToShowFOSSuggestions = false;
		if (!this.FOSSelected) {
			if (!this.inGetStartedController && this.oldData) {
				if (this.oldData.JobTitle !== this.fos) {
					// this.fos = '';
				}
			} else {
				// this.fos = '';
				if (this.inGetStartedController && this.oldDataGT) {
					if (this.oldDataGT.positionName !== this.fos) {
						// this.fos = '';
					}
				} else {
					// this.fos = '';
				}
			}
			this.validateDegree();
		}
	}

	validateFOS() {
		if (this.fos === '') {
			this.FOSErrorMessage = 'Please select your position';
			this.showFOSError = true;
		} else {
			this.showFOSError = false;
		}
	}

	validateCity() {
		if (this.city === '' && this.parentobj.city === '') {
			this.cityErrorMessage = 'Enter location';
			this.showCityError = true;
		} else {
			this.showCityError = false;
		}
	}

	formatGPA(value?: any) {
		this.validateGpa();
	}

	deleteData() {
		if (this.delete) {
			this.deleteFn.emit(this.data.COGUID);
			this.close();
		}
	}

	saveData() {
		this.validateSchool();
		this.validateTimePeriod();
		this.validateDegree();
		this.validateFOS();
		this.validateGpa();
		this.validateCity();

		if ((this.showSchoolError || this.showDegreeError || this.parentobj.showCityError || this.showFOSError || this.showGPAError || this.startDateError || this.endDateError || this.timePeriodError)) {

		} else {
			let guid;
			if (this.data.id) {
				guid = this.id;
			} else if (this.data && this.data.COGUID) {
				guid = this.data.COGUID;
			} else {
				guid = this.utilityService.genGuid();
			}
			let educationObject = {
				key: guid,
				value: {
					id: this.id,
					videoData: this.videoData,
					videoUrl: (this.selectedVideoUrl) ? this.selectedVideoUrl.toString() : '',
					school: this.school,
					schoolId: this.school,
					schoolUrl: this.schoolUrl,
					Location: (this.parentobj.city !== '' ? this.parentobj.city : this.city),
					degree: this.degree,
					degreeId: this.degree,
					fos: this.fos,
					fosId: this.fosId,
					gpa: this.gpa,
					startMonth: Number(this.startMonth.id),
					startYear: this.startYear,
					endMonth: Number(this.endMonth.id),
					endYear: this.endYear,
					notes: this.notes,
					isSaved: false, // status to keep upload status
					city: this.city
				}
			}
			if (this.id || (this.data && this.data.COGUID)) {
				if (this.pageStatus === 1) {
					this.educationEditFn.emit({ data: educationObject, videoEditted: this.videoEditted, ctrl: this.ctrl, videoDeleted: this.videoDeleted })
				} else {
					this.educationEditFnGs.emit({ data: educationObject, videoEditted: this.videoEditted, ctrl: this.ctrl, videoDeleted: this.videoDeleted })
				}
			} else {
				if (this.pageStatus === 1) {
					this.educationAddFn.emit({ data: educationObject, ctrl: this.ctrl, videoEditted: this.videoEditted })
				} else {
					this.educationAddFnGs.emit({ data: educationObject, ctrl: this.ctrl, videoEditted: this.videoEditted })
				}
			}
			this.close();
		}
	}

	validateGpa() {
		if (this.gpa === null || this.gpa < 0) {
			this.GPAErrorMessage = 'Enter between 0 and 10';
			this.showGPAError = true;
		} else if (this.gpa > 9.99) {
			this.GPAErrorMessage = 'GPA should be between 0 & 10';
			this.showGPAError = true;
			this.showGPAError = true;
		} else {
			this.showGPAError = false;
		}
	}

	currentlyWorking() {
		this.endYear = 'Year:';
		this.endMonth = { id: '0' };
	}

	// time period field validation
	private validateTimePeriod() {
		if (this.startMonth.id === '0' || this.startMonth === 'Month' || this.startYear === 'Year:') {
			this.startDateErrorMessage = 'Select month & year';
			this.startDateError = true;
		} else {
			this.startDateError = false;
		}

		if (this.endMonth.id === '0' || this.endMonth === 'Month' || this.endYear === 'Year:') {
			this.endDateErrorMessage = 'Select month & year';
			this.endDateError = true;
		} else {
			this.endDateError = false;
		}

		if (Number(this.startYear) > Number(this.endYear)) {
			this.timePeriodErrorMessage = 'School year end cannot be before start year';
			this.timePeriodError = true;
		} else {
			// this.timePeriodError = false
			if (Number(this.startYear) === Number(this.endYear) && Number(this.startMonth.id) > Number(this.endMonth.id)) {
				this.timePeriodErrorMessage = 'School year end cannot be before start year';
				this.timePeriodError = true;
			} else {
				this.timePeriodError = false;
			}
		}
	}

	removeVideo() {
		this.videoEditted = false;
		this.videoDeleted = true;
		this.videoSelected = false;
		this.selectedVideoUrl = undefined;
		this.videoData = undefined;
		if (document.getElementById('vidPreview')) {
			(document.getElementById('vidPreview') as HTMLMediaElement).muted = true;
		}
	}

	getArray(n: number) {
		return new Array(n);
	}

	adjustCalenderStart(selectedDate: any) {
		console.log(selectedDate)
		let date = new Date()
		if (date.getFullYear() === Number(selectedDate)) {
			this.data.months_start = this.data.months_start.splice(0, date.getMonth() + 2);
		} else {
			if (this.data.months_start.length !== 13) {
				this.data.months_start = [...this.data.months_new];
			}
		}
	}

	adjustCalenderEnd(selectedDate: any) {
		console.log(selectedDate)
		let date = new Date()
		if (date.getFullYear() === Number(selectedDate)) {
			this.data.months_end = this.data.months_end.splice(0, date.getMonth() + 2);
		} else {
			if (this.data.months_end.length !== 13) {
				this.data.months_end = [...this.data.months_new];
			}
		}
	}

	onChange(event: any){
		this.valueUpdated = true;
	}

	close() {
		this.modal.dismiss();
	}

}
