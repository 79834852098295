import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MediaUploadService } from '../../../core/services/mediaUpload.service';
import { UserService } from '../../../core/services/user.service';
import { environment } from 'src/environments/environment';
import { AddEducationModalComponent } from '../add-education-modal/add-education-modal.component';

@Component({
	selector: 'education-video',
	templateUrl: './education-video.component.html',
	styleUrls: ['./education-video.component.scss']
})
export class EducationVideoComponent implements OnInit {

	public fileServerUrl: string = environment.urls.fileServerUrl_Obj;

	@Input() data: any = null;
	@Output() deleteFn: EventEmitter<any> = new EventEmitter<any>();

	public ctrl: string = 'PROFILE_ADD_EDU';
	public isSaved: boolean = true;
	public numLimit: number = 285;
	public showReadLess: any;
	public loadingButton: boolean = false;
	public pageStatus: any;

	constructor(public modalService: NgbModal,
		private sanitizer: DomSanitizer,
		private userService: UserService,
		private mediaUploadService: MediaUploadService) { }

	ngOnInit() {
		if (this.data.VideoUrl) {
			this.data.hasVideo = true;
		} else {
			this.data.hasVideo = false;
		}

		// set video url as trusted & append file server
		if (this.data.isLocalVideo) {
			this.data.VideoUrl = this.data.VideoUrl;
		} else {
			if (this.data.hasVideo) {
				this.data.VideoUrl = this.fileServerUrl + this.data.VideoUrl;
			}
		}

	}

	getImageSrc(imageUrl: any) {
		if (imageUrl) {
			if (imageUrl.includes(this.fileServerUrl)) {
				return imageUrl;
			} else {
				return this.fileServerUrl + imageUrl;
			}
		} else {
			return '';
		}
	}

	readMore() {
		this.numLimit = 2000;
		this.showReadLess = true;
	}

	readLess() {
		this.numLimit = 285;
		this.showReadLess = false;
	}

	edit() {
		this.pageStatus = 1;
		let modal = this.modalService.open(AddEducationModalComponent,
			{ windowClass: 'dual-column-dialog edit-resume-dialog', backdrop: 'static', keyboard: false });
		modal.componentInstance.data = this.data;
		modal.componentInstance.pageStatus = this.pageStatus;
		modal.componentInstance.ctrl = this.ctrl;
		modal.componentInstance.delete = true;
		modal.componentInstance.educationEditFn.subscribe((data: any) => {
			this.educationEdit(data);
		});
		modal.componentInstance.deleteFn.subscribe((data: any) => {
			this.delete();
		});
	}

	delete() {
		let objToUpload = {
			DeleteIds: [
				{
					Type: 'EDUCATION',
					Id: this.data.Id,
				},
			],
		};
		this.userService.profileUpdate(objToUpload).subscribe((response: any) => {
			if (response.Code === 200) {
				this.deleteFn.emit(this.data.COGUID);
			}
		});
	}

	educationEdit(args: any) {
		if (args.data.key === this.data.COGUID && args.ctrl === this.ctrl) {
			// show loading
			this.loadingButton = true;
			let obj = args.data.value;
			let newData = {
				COGUID: args.data.key,
				College: {
					Id: obj.schoolId,
					Name: obj.school,
					LogoUrl: obj.schoolUrl,
				},
				Degree: obj.degree,
				DegreeId: obj.degreeId,
				End: obj.endYear,
				FieldOfStudy: obj.fos,
				GPA: obj.gpa,
				Id: obj.id,
				Notes: obj.notes,
				Start: obj.startYear,
				StartMonth: obj.startMonth,
				EndMonth: obj.endMonth,
				Location: {
					Country: "US",
					Administrative_Area_Level_1: "",
					Locality: obj.Location,
					Lat: 0.0,
					Long: 0.0,
				},
				VideoUrl: obj.videoUrl,
				ThumbnailUrl: this.data.ThumbnailUrl,
				hasVideo: !(
					obj.videoUrl.toString() === "" ||
					obj.videoUrl.toString() === this.fileServerUrl
				),
				isSaved: true
			};
			this.data = newData;
			if (args.videoEditted) {
				this.data.VideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(obj.videoUrl);
			}
			let objToUpload = {
				UpdateRecords: [
					{
						Type: "EDUCATION",
						Data: {
							Id: obj.id,
							Notes: obj.notes,
							COGUID: args.data.key,
							Start: obj.startYear,
							End: obj.endYear,
							CollegeId: obj.schoolId,
							GPA: obj.gpa,
							DegreeId: obj.degreeId,
							FieldOfStudy: obj.fos,
							StartMonth: obj.startMonth,
							EndMonth: obj.endMonth,
							Location: {
								Country: "US",
								Administrative_Area_Level_1: "",
								Locality: obj.Location,
								Lat: 0.0,
								Long: 0.0,
							},
						},
					},
				],
			};

			this.userService.profileUpdate(objToUpload).subscribe((response: any) => {
				if (args.videoEditted) {
					this.mediaUploadService.uploadVideo(
						obj.videoData,
						'EDU_VIDEO',
						obj.id)
						.subscribe((response: any) => {
							this.data.ThumbnailUrl = this.fileServerUrl + response.ThumbnailURL;
							// show loading
							this.loadingButton = false;
						}
						);
				} else if (args.videoDeleted) {
					this.data.ThumbnailUrl = '';
					// show loading
					this.loadingButton = false;
					this.mediaUploadService.removePhoto('EDU_VIDEO', obj.id).subscribe(
						(response: any) => {
							// console.log(response)
						});
				} else {
					// show loading
					this.loadingButton = false;
				}
			});

		}
	}

}
