import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'common-alert',
	templateUrl: './common-alert.component.html',
	styleUrls: ['./common-alert.component.scss']
})
export class CommonAlertComponent {

	@Input() header: string = 'Information';
	@Input() msg: string = '';
	@Input() contentType = 'Text'

	constructor(public modal: NgbActiveModal) { }

	ok() {
		this.modal.close();
	}

}
