import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { EventService } from '../../../core/services/event.service';
import { UserService } from '../../../core/services/user.service';

@Component({
	selector: 'phone-confirmation-modal',
	templateUrl: './phone-confirmation-modal.component.html',
	styleUrls: ['./phone-confirmation-modal.component.scss']
})
export class PhoneConfirmationModalComponent implements OnInit {

	@Input() number: any;

	public code: string = '';
	public codeError: boolean = false;
	public nullCodeError: boolean = false;

	constructor(public modal: NgbActiveModal,
		private router: Router,
		private userService: UserService,
		private eventService: EventService,
		private cookieService: CookieService) { }

	ngOnInit() {

		this.eventService.subscribe('code-verified', (payload: any) => {
			if (payload.response.status === 200 && payload.response.Code === 200) {
				this.modal.dismiss();
				if (this.cookieService.check('stageToRedirect')) {
					if (Number(this.cookieService.get('stageToRedirect')) === 0) {
						this.userService.setCookie('stage', '1', false);
						this.router.navigateByUrl('/addPhoto');
					} else {
						this.userService.setCookie('stage', this.cookieService.get('stageToRedirect'), false);
						this.userService.redirectUser(Number(this.cookieService.get('stageToRedirect')));
					}
				} else {
					this.userService.setCookie('stage', '1', false);
					this.router.navigateByUrl('/addPhoto');
				}
			} else {
				this.codeError = true;
			}
		});

	}

	confirmCode() {
		this.codeError = false;
		this.nullCodeError = false;
		if (this.code === '') {
			this.nullCodeError = true;
		} else {
			this.userService.verifyCode(Number(this.code)).subscribe();
		}
	}

	keycount(code: any) {
		if (code.length == 4) {
			this.code = code;
			this.confirmCode();
		}
	}

	resendCode() {
		this.eventService.broadcast('resend-code', {});
	}

	cancelCode() {
		this.eventService.broadcast('cancel-code', {});
	}

	maxLengthCheck() {
		if (this.code && this.code.toString().length > 4) {
			this.code = this.code.toString().slice(0, 4);
		}
	}

	closeThisDialog() {
		this.modal.dismiss();
	}

}
