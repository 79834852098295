import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from './core/services/google-analytics.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	title = 'unnanu-recruit-web-angular';

	constructor(private _ga: GoogleAnalyticsService) { }

	ngOnInit(): void {
		this._ga.initialize();
	}
}

