<div class="modal-header">
	<h1 class="modal-title">Onboarding Documents - {{offer.hire_company_name}}</h1>
	<button type="button" class="close" aria-label="Close" (click)="closeThisDialog()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">
	<div class="scroll-content">
		<div class="item-wrapper" *ngFor="let onboard of offer.onboarding_attachments">
			<h3 class="float-left">{{onboard.attachment_title}}</h3>

			<div class="status waiting" *ngIf="onboard.attachment_status === 1">Awaiting Response</div>
			<div class="status sent" *ngIf="onboard.attachment_status === 2">Response Sent</div>
			<div class="status received" *ngIf="onboard.attachment_status === 3">Received</div>
			<div class="msg-wrapper">
				<!--  <p>{{onboard.send_at | date:'EEE, MMM d, y @ h:mm a'}} Madhu -->
				<p>{{onboard.description}}</p>
			</div>
			<div class="button-wrapper">
				<a class="icon-download-alt pull-left icon-2x" style="padding: 7px; " target="_self"
					href="{{attachmentUrl + onboard.attachment_url}}"></a>
				<button style="cursor: pointer;" attachmentView [value]="attachmentUrl + onboard.attachment_url"
					class="btn view-document float-left">View Document</button>
				<button class="btn attach-send float-left" [hidden]="!onboard.is_require_response"
					[disabled]="onboard.disableStage || offer.offer_status == 3 || offer.offer_status == 4"><input
						type="file" accept=".pdf,.docx,.doc"
						(change)="onPreviewAttachmentView($event, onboard.attachment_id)" />Attach & Send</button>
				<div class="info-wrapper float-right">
					<p class="sent" *ngIf="onboard.attachmentSent"><span></span> Attachment Sent!</p>
					<p class="sent attachments" *ngIf="onboard.viewSent && onboard.is_require_response"
						(click)="onboard.showAttachments = true; onboard.hideAttachments = true; onboard.viewSent = false">
						View sent attachments <span></span></p>
					<p class="sent attachments up"
						*ngIf="onboard.hideAttachments && onboard.response_attachments.length !== 0"
						(click)="onboard.hideAttachments = false; onboard.viewSent = true; onboard.showAttachments = false">
						Hide sent attachments <span></span></p>
					<p class="sent uploading" *ngIf="onboard.attachmentPreview"><span></span> Uploading</p>
				</div>
			</div>
			<div class="due-date" *ngIf="onboard.due_date">Due on: {{onboard.due_date | date: 'EEE, MMM d, y'}}</div>
			<div class="attachment-wrapper" *ngIf="onboard.showAttachments">
				<div class="attachment-item" *ngFor="let ResponseAttachments of onboard.response_attachments">
					<p class="item">
						<span
							[ngClass]="{'pdf':ResponseAttachments.attachment_type == 1, 'image':ResponseAttachments.attachment_type == 2, 'video ':ResponseAttachments.attachment_type == 3, 'audio ':ResponseAttachments.attachment_type == 4, 'other ':ResponseAttachments.attachment_type == 5, 'doc':ResponseAttachments.attachment_type == 6 }"></span>
						<a style="cursor: pointer;" attachmentView
							[value]="attachmentUrl + ResponseAttachments.attachment_url">{{ResponseAttachments.file_name}}</a>
						<a class="icon-download-alt" style="padding: 3px 0px 0px 10px; " target="_self"
							href="{{attachmentUrl + ResponseAttachments.attachment_url}}"></a>
					</p>
					<div class="status waiting" *ngIf="ResponseAttachments.response_status === 1">In Review</div>
					<div class="status sent" *ngIf="ResponseAttachments.response_status === 2">Accepted</div>
					<div class="status decline" *ngIf="ResponseAttachments.response_status === 3">Not Accepted</div>
					<div class="status cursor-pointer" *ngIf="ResponseAttachments.response_status === 3" (click)="openComment(ResponseAttachments.comment)">
						<img class="" width="16" src="../../../assets/images/information.png">
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
