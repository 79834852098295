import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { UserService } from '../../core/services/user.service';
import { EventService } from '../../core/services/event.service';
import { SocketIOService } from '../../core/services/socket-io.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { JobBoardRecruitService } from '../../core/services/jobBoardRecruit.service';
import { ProfileDataService } from '../../core/services/profile-data.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfirmAlertComponent } from '../../shared/components/confirm-alert/confirm-alert.component';

@Component({
	selector: 'app-header-dashboard',
	templateUrl: './dashboard-header.component.html',
	styleUrls: ['./dashboard-header.component.scss']
})
export class DashboardHeaderComponent implements OnInit, AfterViewInit, OnDestroy {

	public jobboard_URL_with_token: string = '';
	public jobboard_URL: string = environment.urls.job_board;
	public fileServerUrlHire: string = environment.urls.fileServerUrlHire_Obj;
	public fileServerUrl: string = environment.urls.fileServerUrl_Obj;

	public showIncompleteProfileBar: boolean = false;
	public selectedJobData: any = {};

	public newMessageCount: number = 0;
	public newInterviewCount: number = 0;
	public newOfferCount: number = 0;
	public offerExistedCount: number = 0;

	public OfferIdArray: any = [];
	public unreadMessageArray: any = [];
	public unreadInterviewArray: any = [];

	public BasicInformation: any = {};

	constructor(private router: Router,
		private cookieService: CookieService,
		private socketIOService: SocketIOService,
		private eventService: EventService,
		private userService: UserService,
		private jobBoardRecruitService: JobBoardRecruitService,
		private profileDataService: ProfileDataService,
		private modalService: NgbModal,
		private sanitizer: DomSanitizer) { }

	ngOnInit(): void {

		if (this.cookieService.check('token')) {
			this.jobboard_URL_with_token = `${this.jobboard_URL}?token=${this.cookieService.get('token')}`;
			this.getUserProfileData();
			this.getConversation();
		}

		if (this.cookieService.check('incomplete_profile')) {
			this.jobBoardRecruitService.getSelectedJobData(this.cookieService.get('incomplete_profile'))
				.subscribe((response: any) => {
					console.log('call job board service to get job details about given jobid');
					console.log(response);
					if (response.Code === 200) {
						this.selectedJobData = response.Data;
						this.showIncompleteProfileBar = true;
					} else {
						// link broken
						// this.router.navigateByUrl('/');
					}
				});
		}

		this.eventService.subscribe('interviewMarkAsRead', (payload) => {
			if (this.newInterviewCount !== 0) {
				this.newInterviewCount -= 1;
				if (this.newInterviewCount === 0) {
					this.eventService.broadcast('marlAllInterviewsAsRead', true);
				}
			}
		});

		this.eventService.subscribe('offerMarkAsRead', (payload) => {
			if (this.newOfferCount !== 0) {
				this.newOfferCount -= 1;
				let index = this.OfferIdArray.findIndex((offer: any) => offer === payload.offer_content_id);
				this.OfferIdArray.splice(index, 1);
			}
		});

		this.eventService.subscribe('mark-read', (payload) => {
			let continueLoop: boolean = true;
			this.unreadMessageArray.forEach((value: any) => {
				if (continueLoop) {
					if (value.Conversation_id === payload.data.Conversation_id) {
						this.newMessageCount -= value.Unread_Message_Count;
						continueLoop = false;
						console.log('message readed :');
						console.log(value.Unread_Message_Count);
						value.Unread_Message_Count = 0;
					}
				}
			});
			console.log('unreadMessageArray');
			console.log(this.unreadMessageArray);
		});
	}

	ngAfterViewInit(): void {
		if (this.cookieService.check('token')) {
			this.activateSocketListeners();
		}
	}

	ngOnDestroy() {
		this.socketIOService.removeListener('MessageReceived');
		this.socketIOService.removeListener('InterviewReceived');
		this.socketIOService.removeListener('ReceivedPostponeInterview');
		this.socketIOService.removeListener('NewJobOffer');
		this.socketIOService.removeListener('ReceiveOnboard');
	}

	getCurrentUrl() {
		return this.router.url;
	}

	getUserProfileData() {
		// add preloader
		this.profileDataService.setProfileDataLoaded(false);
		this.profileDataService.setVerificationStatusLoaded(false);
		this.profileDataService.setVerifyStatus('');
		this.userService.profileInfo().subscribe((response: any) => {
			if (response.status === 200 && response.Code === 200) {
				// hide preloader
				this.profileDataService.setProfileDataLoaded(true);
				this.profileDataService.setAllProfileData(response.Data);
				let basicInfo = response.Data.BasicInformation;
				if (response.Data.BasicInformation.DpUrl === '') {
					basicInfo.DpUrl = '../../../assets/images/default-profile-picture-thumb.svg';
					basicInfo.DpUrl_2 = '../../../assets/images/default-profile-picture-new.svg';
				} else {
					basicInfo.DpUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
						this.fileServerUrl + response.Data.BasicInformation.DpUrl
					);
					basicInfo.DpUrl_2 = basicInfo.DpUrl;
				}
				this.profileDataService.setBasicInformation(basicInfo);
				this.BasicInformation = this.profileDataService.getBasicInformation();
				this.BasicInformation.FirstName = this.profileDataService.getBasicInformation().FirstName;

				if (response.Data.Resume) {
					this.profileDataService.setEditResumeId(response.Data.Resume.resume_id);
				}

				this.eventService.broadcast('profileDataLoaded', {});

				// this.userService.checkPayment().subscribe((response2: any) => {
				//   if (response2.status === 200) {
				//     if (response2.Code === 200) {
				//       let verified = this.checkIfVerified(response.Data.EducationHistory, response.Data.WorkHistory);
				//       if (verified) {
				//         this.profileDataService.setVerifyStatus('verified');
				//       } else {
				//         this.profileDataService.setVerifyStatus('pending');
				//       }
				//     } else {
				//       this.profileDataService.setVerifyStatus('not_verified');
				//     }
				//   } else {
				//     this.profileDataService.setVerifyStatus('not_verified');
				//   }
				//   this.profileDataService.setVerificationStatusLoaded(true);
				//   this.eventService.broadcast('verificationStatusLoaded',{});
				// });
			}
		});
	}

	private checkIfVerified(eduData: any, expData: any) {
		let eduVerified: boolean = false;
		let expVerified: boolean = false;

		console.log('eduData : DashboardHeaderController.js');
		console.log(eduData);

		console.log('expData : DashboardHeaderController.js');
		console.log(expData);

		eduData.forEach(function (edu: any) {
			if (edu.isVerified === 1) {
				if (expData.length === 0) expVerified = true;
				eduVerified = true;
			}
		});

		expData.forEach(function (exp: any) {
			if (exp.isVerified === 1) {
				if (eduData.length === 0) eduVerified = true;
				expVerified = true;
			}
		});

		return eduVerified && expVerified;
	}

	private getConversation() {
		this.unreadMessageArray.length = 0;
		this.unreadInterviewArray.length = 0;
		this.userService.getHeaderDataCount().subscribe((response: any) => {
			// set response to scope
			if (response.status == 200 && response.Code == 200) {
				this.unreadMessageArray = response.Data.UnreadMessges;
				this.newInterviewCount = response.Data.UnreadInterviews.length;
				this.newOfferCount = response.Data.UnreadOffers.length;
				this.unreadMessageArray.forEach((value: any) => {
					this.newMessageCount += value.Unread_Message_Count;
				});
			} else {
				console.log('getHeaderDataCount error');
				console.log(response);
				this.signOutFromUnnanu();
			}
		});
	}

	private signOutFromUnnanu() {
		this.userService.signOutFromUnnanu().subscribe();
	}

	activateSocketListeners() {
		// new mesage receivd
		this.socketIOService.on('MessageReceived', (data) => {
			// check message is archived or not
			this.newMessageCount += 1;
			this.updateConversationArray(data.conversationId);
		});

		// new interview received
		this.socketIOService.on('InterviewReceived', (data) => {
			console.log('interview received');
			console.log(data);
			this.newInterviewCount += 1;
		});

		// interview reschedule received
		this.socketIOService.on('ReceivedPostponeInterview', (data) => {
			console.log('interview rescheduled');
			console.log(data);
			this.newInterviewCount += 1;
		});

		this.socketIOService.on('NewJobOffer', (payload) => {
			console.log('newjob received');
			console.log(payload);
			this.newOfferCount += 1;
		});

		this.socketIOService.on('ReceiveOnboard', (data) => {
			this.offerExistedCount = 0;
			for (let i = 0; i < this.OfferIdArray.length; i++) {
				if (this.OfferIdArray[i] == data.offer_content_id) {
					this.offerExistedCount += 1;
				} else {
					this.offerExistedCount = 0;
				}
			}
			if (this.offerExistedCount == 0) {
				this.OfferIdArray.push(data.offer_content_id);
				this.newOfferCount += 1;
			}
		});
	}

	private updateConversationArray(convId: any) {
		let continueLoop: boolean = true;
		let foundConv: boolean = false;

		this.unreadMessageArray.forEach((value: any) => {
			if (continueLoop) {
				if (value.Conversation_id === convId) {
					value.Unread_Message_Count += 1;
					continueLoop = false;
					foundConv = true;
				}
			}
		});

		// conversation already not exist
		if (!foundConv) {
			let newConv: any = {};
			newConv.Conversation_id = convId;
			newConv.Unread_Message_Count = 1;
			this.unreadMessageArray.push(newConv);
		}
	}

	goBackToApplication() {
		this.router.navigateByUrl(`/jobboard/apply/${this.cookieService.get('incomplete_profile')}`);
		this.cookieService.delete('incomplete_profile', '/');
	}

	cancelUpdatingProfile() {
		let modal = this.modalService.open(ConfirmAlertComponent, { windowClass: 'alert-pop-up-style-v2', backdrop: 'static', keyboard: false });
		modal.componentInstance.header = 'Cancel application';
		modal.componentInstance.msg = `You're almost done with your application. Are you sure you want to cancel now?`;
		modal.result.then((closed) => {
			this.cookieService.delete('incomplete_profile', '/');
			this.showIncompleteProfileBar = false;
			this.applicationCanceledPopup();
			console.dir(closed);
		},
			(dismissed) => {
				console.log('Rejected cancel application');
			});
	}

	private applicationCanceledPopup() {
		let modal = this.modalService.open(ConfirmAlertComponent, { windowClass: 'alert-pop-up-style-v2', backdrop: 'static', keyboard: false });
		modal.componentInstance.header = 'Application canceled';
		modal.componentInstance.msg = 'Your application has been canceled. Do you want to stay in your profile?';
		modal.result.then((closed) => {

		},
		(dismissed) => {
			this.signOutFromUnnanu();
		});
	}

	signOut() {
		if (this.cookieService.check('_jobboard')) {
			this.logoutAndCancelApplication();
		} else {
			this.signOutFromUnnanu();
		}
	}

	private logoutAndCancelApplication() {
		let modal = this.modalService.open(ConfirmAlertComponent, { windowClass: 'alert-pop-up-style-v2', backdrop: 'static', keyboard: false });
		modal.componentInstance.header = 'Cancel application';
		modal.componentInstance.msg = 'Are you sure you want to cancel now?';
		modal.result.then((closed) => {
			this.signOutFromUnnanu();
		},
		(dismissed) => {
			console.log('Rejected cancel application');
		});
	}

}
