import { NgModule } from '@angular/core';
import { UserService } from './services/user.service';
import { UtilityService } from './services/utility.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HeaderInterceptor } from './interceptors/header.interceptor';
import { CookieService } from 'ngx-cookie-service';
import { SocketIOService } from './services/socket-io.service';
import { EventService } from './services/event.service';
import { JobBoardRecruitService } from './services/jobBoardRecruit.service';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { ContextService } from './services/context.service';
import { ResponseInterceptor } from './interceptors/response.interceptor';
import { MediaUploadService } from './services/mediaUpload.service';
import { ProfileDataService } from './services/profile-data.service';
import { AutocompleteService } from './services/autocomplete.service';
import { MessageService } from './services/message.service';
import { OfferService } from './services/offer.service';
import { InterviewService } from './services/interview.service';

@NgModule({
	declarations: [],
	imports: [
	],
	exports: [
	],
	providers: [
		CookieService,
		UserService,
		SocketIOService,
		EventService,
		UtilityService,
		JobBoardRecruitService,
		ContextService,
		MediaUploadService,
		ProfileDataService,
		AutocompleteService,
		MessageService,
		OfferService,
		InterviewService,
		{ provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
	]
})
export class CoreModule { }
