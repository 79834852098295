import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'file-view-modal',
	templateUrl: './file-view-modal.component.html',
	styleUrls: ['./file-view-modal.component.scss']
})
export class FileViewModalComponent implements OnInit {

	@Input() fileUrl: any;

	public url: any;

	constructor(public modal: NgbActiveModal,
		private sanitizer: DomSanitizer) { }

	ngOnInit() {
		if (this.fileUrl.split('.').pop() === 'html' || this.fileUrl.split('.').pop() === 'odt') {
			this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.fileUrl);

		} else {
			this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
				`https://docs.google.com/viewer?url=${this.fileUrl}&embedded=true`
			);
		}
	}

}
