import { Injectable } from "@angular/core";

@Injectable()
export class ContextService {

	private headerContext: string = '';
	private footerContext: string = '';
	private showDashboard: boolean = false;

	constructor() { }

	getHeaderContext() {
		return this.headerContext;
	}

	setHeaderContext(context: string) {
		this.headerContext = context;
	}

	getFooterContext() {
		return this.footerContext;
	}

	setFooterContext(context: string) {
		this.footerContext = context;
	}

	getShowDashboard() {
		return this.showDashboard;
	}

	setShowDashboard(showDashboard: boolean) {
		this.showDashboard = showDashboard;
	}
}
