import { Injectable } from '@angular/core';
@Injectable()
export class UtilityService {

	public readonly emailFormat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	constructor() { }

	s4() {
		return Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1);
	}

	genGuid() {
		return (
			this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + '-'
			+ this.s4() + this.s4() + this.s4()
		);
	}

	base64toBlob(dataURI: any) {
		// convert base64/URLEncoded data component to raw binary data held in a string
		let byteString;
		if (dataURI.split(',')[0].indexOf('base64') >= 0) {
			byteString = atob(dataURI.split(',')[1]);
		} else {
			byteString = unescape(dataURI.split(',')[1]);
		}

		// separate out the mime component
		let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

		// write the bytes of the string to a typed array
		let ia = new Uint8Array(byteString.length);
		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}

		return new Blob([ia], { type: mimeString });
	}

	compareObjects(obj1: any, obj2: any) {
		let matchCount = 0;
		obj1.forEach((value: any, key: any) => {
			if (typeof value !== 'object') {
				if (obj2[key] == value) {
					matchCount++;
					// console.log(key + ' wasn a match')
				} else {
					// console.log(key + ' wasn`t a match')
				}
			} else {
				// it's an object so loop through that too
				matchCount += this.compareObjects(obj2[key], value);
			}
		});
		return matchCount;
	}
}
