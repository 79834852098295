<div class="modal-header">
	<h3 class="modal-title" id="modal-title">Confirm "Deactivate"</h3>
</div>
<div class="modal-body">
	<p>Your profile and information will be deleted from the platform and no longer accessible to you and you have to create a new account to access the platform.</p>
</div>
<div class="modal-footer text-right">
	<div class="button-wrapper">
		<button type="button" class="btn yes-button" (click)="deactivate()">Deactivate</button>
		<button type="button" class="btn no-button" (click)="cancel()">Cancel</button>
	</div>
</div>
