<div class="modal-header">
	<h4 class="modal-title" id="modal-title">{{header}}</h4>
</div>
<div class="modal-body">
	<div *ngIf="contentType === 'Text'">{{msg}}</div>
	<div *ngIf="contentType === 'HTML'" [innerHTML]="msg"></div>
</div>
<div class="modal-footer text-right">
	<div class="button-wrapper">
		<button type="button" class="btn ok-button" (click)="ok()">Ok</button>
	</div>
</div>
