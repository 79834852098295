<div class="social-login">
	<button class="btn continue-with-facebook" (click)="applePressed()" [disabled]="isAeSigningUp">
		<div>
			<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
				<path
					d="M14.152 12.258a8.293 8.293 0 0 1-.82 1.476c-.43.614-.785 1.04-1.055 1.274-.422.39-.875.586-1.359.597-.348 0-.766-.097-1.254-.296-.488-.2-.937-.301-1.348-.301-.43 0-.894.101-1.386.3-.496.2-.895.305-1.2.313-.464.02-.925-.184-1.39-.613-.293-.254-.66-.695-1.102-1.32-.476-.668-.863-1.438-1.168-2.32-.328-.954-.492-1.872-.492-2.766 0-1.02.223-1.903.664-2.641A3.892 3.892 0 0 1 3.63 4.555a3.744 3.744 0 0 1 1.879-.528c.367 0 .851.114 1.453.336.598.227.98.34 1.152.34.125 0 .551-.133 1.278-.398.683-.246 1.261-.348 1.734-.309 1.281.102 2.246.61 2.887 1.52-1.149.695-1.715 1.668-1.703 2.918.011.972.363 1.78 1.058 2.425.313.297.664.528 1.055.692-.086.246-.176.48-.27.707zM11.211.68c0 .765-.277 1.476-.836 2.136-.668.782-1.48 1.235-2.36 1.164A2.306 2.306 0 0 1 8 3.691c0-.734.316-1.515.883-2.156A3.459 3.459 0 0 1 9.96.727c.434-.215.848-.332 1.234-.352.012.102.016.207.016.305zm0 0">
				</path>
			</svg><span>Continue with Apple</span>
		</div>
		<div class="signinsignupPreloader" [hidden]="!isAeSigningUp"></div>
	</button>
	<div class="or-wrapper text-center">
		<ng-container *ngIf="showOr">
			<p class="or">or</p>
			<hr />
		</ng-container>
	</div>
	<button class="btn continue-with-facebook" (click)="googlePressed()" [disabled]="isGeSigningUp">
		<div>
			<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
				<path
					d="M16.51 8H8.98v3h4.3c-.18 1-.74 1.48-1.6 2.04v2.01h2.6a7.8 7.8 0 002.38-5.88c0-.57-.05-.66-.15-1.18z"
					fill="#4285F4"></path>
				<path d="M8.98 17c2.16 0 3.97-.72 5.3-1.94l-2.6-2a4.8 4.8 0 01-7.18-2.54H1.83v2.07A8 8 0 008.98 17z"
					fill="#34A853"></path>
				<path d="M4.5 10.52a4.8 4.8 0 010-3.04V5.41H1.83a8 8 0 000 7.18l2.67-2.07z" fill="#FBBC05"></path>
				<path d="M8.98 4.18c1.17 0 2.23.4 3.06 1.2l2.3-2.3A8 8 0 001.83 5.4L4.5 7.49a4.77 4.77 0 014.48-3.3z"
					fill="#EA4335"></path>
			</svg>
			<span>Continue with Google</span>
		</div>
		<div class="signinsignupPreloader" [hidden]="!isGeSigningUp"></div>
	</button>
	<div class="or-wrapper text-center">
		<ng-container *ngIf="showOr">
			<p class="or">or</p>
			<hr />
		</ng-container>
	</div>
	<button class="btn continue-with-facebook" (click)="facebookPressed()" [disabled]="isFbSigningUp">
		<div>
			<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
				<path fill="#365899" fill-rule="evenodd"
					d="M0 0v18.989h10.113v-7.335H7.64V8.659h2.472V6.145a3.311 3.311 0 0 1 3.311-3.312h2.585v2.693h-1.85c-.58 0-1.052.471-1.052 1.052V8.66h2.855l-.395 2.995h-2.46v7.335h5.882V0H0z" />
			</svg><span>Continue with Facebook</span>
		</div>
		<div class="signinsignupPreloader" [hidden]="!isFbSigningUp"></div>
	</button>
</div>
