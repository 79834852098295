<div class="edit-profile-dialog-content no-close-btn-header d-flex flex-row"
	[ngClass]="{'edit-resume-popup': ctrl === 'PROFILE_ADD_EXP'}">
	<div class="popup-column-group profile-photo-video-column">
		<div class="column-title">Experience</div>
		<div class="photo-video-ui">
			<div class="photo-video-container">
				<div class="profile-video-ui-wrapper">
					<video (onloadeddata)="positionVideo()" id="vidPreview" [hidden]="!videoSelected"
						[src]="selectedVideoUrl" autoplay loop></video>
				</div>
				<img id="imgPreview" [hidden]="videoSelected" class="imgPreview" height="100%" src="{{selectedPhoto}}">
				<img [hidden]="photoSelected || videoSelected" class="noProfilePhoto_img" width="100%" height="100%"
					src="../../../../assets/images/default-profile-picture-new.svg">
				<div class="ui-popover openToggler">
					<button id="muteBtn" [hidden]="!videoSelected" (click)="muted(!muteClassAdd)"
						class="item-video-sound-control" [ngClass]="{'mute' : muteClassAdd}">
						<img class="audio-control-icon-img mute-icon"
							src="../../../../assets/images/sound-muted-icon.png"
							srcset="../../../../assets/images/sound-muted-icon@2x.png 2x, ../../../../assets/images/sound-muted-icon@3x.png 3x">
						<img class="audio-control-icon-img sound-icon"
							src="../../../../assets/images/sound-unmuted-icon.png"
							srcset="../../../../assets/images/sound-unmuted-icon@2x.png 2x, ../../../../assets/images/sound-ummuted-icon@3x.png 3x">
					</button>
				</div>
			</div>
			<div ngbDropdown class="camera-menu dropdown">
				<button ngbDropdownToggle class="btn dropdown-toggle" type="button" id="cameraMenuDropdown">
					<img src="../../../../assets/images/profile-camera-icon.png"
						srcset="../../../../assets/images/profile-camera-icon@2x.png 2x, ../../../../assets/images/profile-camera-icon@3x.png 3x"
						class="profile-camera-icon">
				</button>
				<div ngbDropdownMenu class="dropdown-menu dropdown-menu-right" aria-labelledby="cameraMenuDropdown">
					<a ngbDropdownItem (click)="recordVideo()" class="dropdown-item">Take a Video</a>
				</div>
			</div>
		</div>
		<div class="photo-video-hint">
			Add a video talking about your professional experience, activities, and achievements
			earned while you were at this company.
		</div>
	</div>
	<div class="popup-column-group form-container-column edit-profile-content">
		<div class="modal-header">
			<h4 class="modal-title" id="modal-title"></h4>
		</div>
		<div class="modal-body">
			<input type="hidden" [(ngModel)]="pageStatus" value="{{pageStatus}}" />
			<div class="unnanu-form-group-style-1 has-sussgestions-dropdown">
				<label class="unnanu-input-label-style-1">Company<span class="requiredAsterix">*</span></label>
				<input inputRestriction [ngClass]="{'error': showCompanyError}" (blur)="hideCompanySuggestions()"
					maxlength="100" [(ngModel)]="company" type="" name="company"
					class="form-control unnanu-text-input-style-1" id="" value=""
					placeholder="Enter reference from list or add" [ngbTypeahead]="searchCompany"
					(selectItem)="companySelectedFn($event)" [resultTemplate]="rt" required (ngModelChange)="onChange($event)">
				<ng-template #rt let-r="result" let-t="term">
					<img src="{{r.LogoUrl || '../../../../assets/images/education-default.jpg'}}" class="mr-1"
						style="width: 16px">
					<ngb-highlight [result]="r.Name" [term]="t"></ngb-highlight>
				</ng-template>
				<label class="error" [hidden]="!showCompanyError">Enter
					company name</label>
			</div>
			<div class="unnanu-form-group-style-2 has-sussgestions-dropdown">
				<label class="unnanu-input-label-style-1">Title<span class="requiredAsterix">*</span></label>
				<input inputRestriction [ngClass]="{'error': showPositionError}" maxlength="100" [(ngModel)]="position"
					type="" name="" class="form-control unnanu-text-input-style-1" id="" value=""
					placeholder="Titel at this company or add" (blur)="hidePositionSuggestions()"
					[ngbTypeahead]="searchPosition" (selectItem)="positionSelected($event)"
					[resultFormatter]="positionFormatter" (ngModelChange)="onChange($event)">
				<label [hidden]="!showPositionError" class="error">Enter title</label>
			</div>
			<div class="unnanu-form-group-style-1 has-sussgestions-dropdown">
				<label class="unnanu-input-label-style-1">Location<span class="requiredAsterix">*</span></label>
				<city-suggestion [data]="data" [parentobj]="parentobj" [showCityError]="showCityError" (cityUpdated)="onChange($event)">
				</city-suggestion>
				<label [hidden]="!parentobj.showCityError" class="error">Enter
					Location</label>
			</div>
			<div class="unnanu-form-group-style-1">
				<label class="unnanu-input-label-style-1">Description</label>
				<textarea [(ngModel)]="description" rows="1" class="form-control unnanu-text-input-style-1"
					[ngClass]="{error: remaining() < 0}" maxlength="{{ maxLength }}" (ngModelChange)="onChange($event)"></textarea>
				<span *ngIf="remaining() < 0">{{ remaining() }}</span>
			</div>
			<div class="unnanu-form-group-style-1">
				<label class="unnanu-input-label-style-1">Time Period<span class="requiredAsterix">*</span></label>
				<div class="has-no-end-time">
					<span class="unnanu-check-box-style-1">
						<input inputRestriction id="ItemDoesNotExpire" [(ngModel)]="isCurrentlyWorking" type="checkbox"
							name="" class="form-check" (click)="currentlyWorking()" (ngModelChange)="onChange($event)"/>
						<label for="ItemDoesNotExpire"></label>
					</span>
					<label class="has-no-end-time-label">Currently Work Here</label>
				</div>
				<div class="time-duration-wrapper d-flex">
					<div class="duration-time has-month">
						<div class="month-year-group d-flex justify-content-between">
							<div class="year">
								<select [(ngModel)]="startYear" class="form-control periodMenu yearMenu"
									name="date_start[year]" required (ngModelChange)="onChange($event)">
									<option value="Year:">Year</option>
									<option *ngFor="let i of getArray(64); let $index=index"
										value="{{ currentYear - $index }}">
										{{ currentYear - $index }}</option>
								</select>
							</div>
							<div class="month">
								<select class="form-control periodMenu yearMenu" name="date_start[year]" id="mySelect"
									[(ngModel)]="startMonth.id" required (ngModelChange)="onChange($event)">
									<option *ngFor="let option of data.months_start;" value="{{ option.id }}">
										{{ option.name }}</option>
								</select>
							</div>
						</div>
						<label class="error" [hidden]="!startDateError">{{startDateErrorMessage}}</label>
						<label class="error" [hidden]="!timePeriodError">{{timePeriodErrorMessage}}</label>
					</div>
					<div class="duration-to-label">-</div>
					<div class="duration-time duration-end-time has-month" [hidden]="isCurrentlyWorking">
						<div class="month-year-group d-flex justify-content-between">
							<div class="year">
								<select [(ngModel)]="endYear" class="form-control periodMenu yearMenu"
									name="date_start[year]" (ngModelChange)="onChange($event)">
									<option value="Year:">Year</option>
									<option *ngFor="let i of getArray(50); let $index=index"
										value="{{ currentYear - $index }}">
										{{ currentYear - $index }}</option>
								</select>
							</div>
							<div class="month">
								<select class="form-control periodMenu yearMenu" name="date_start[year]" id="mySelect"
									[(ngModel)]="endMonth.id" required (ngModelChange)="onChange($event)">
									<option *ngFor="let option of data.months_end;" value="{{ option.id }}">
										{{ option.name }}</option>
								</select>
							</div>
						</div>
						<label class="error" [hidden]="!endDateError">{{endDateErrorMessage}}</label>
					</div>
					<!-- End of the to time select dropdown -->
					<div [hidden]="!isCurrentlyWorking" class="duration-time duration-end-time">
						<div class="no-end-date-label">Present</div>
					</div>
				</div>
				<br>
				<span class="requiredAsterix">*</span>
				<label class="unnanu-input-label-style-1">Required Information </label>
			</div>
		</div>
		<div class="modal-footer text-right">
			<div class="footer-buttons-row">
				<button type="button" class="delete-card-button large float-left" (click)="deleteData()"
					*ngIf="data.COGUID">
					Delete
				</button>
				<button type="button" class="cancel-changes-button large" (click)="close()">
					Cancel
				</button>
				<button type="button" class="save-changes-button large" [disabled]="!valueUpdated || !company || !position
                    || !parentobj.city || !startYear || startYear === 'Year:' || startMonth.id === '0'
                    || hasToShowCompanySuggestions || hasToShowPositionSuggestions || parentobj.showCityError"
					(click)="saveData()">
					Save Changes
				</button>
			</div>
		</div>
	</div>
</div>
