import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';

@Injectable()
export class AutocompleteService {
	private serverUrl = environment.urls.serverUrl_Obj;

	constructor(private http: HttpClient) { }

	get(text: any, type: any) {
		return this.http.get(`${this.serverUrl}autocomplete/${type}/${encodeURI(text.trim())}`);
	}

}
