<div class="modal-header">
	<h1 class="modal-title">We’ve sent you a code</h1>
</div>
<div class="modal-body">
	<div class="code-sent-text">Unnanu just sent a text message with a verification code to <strong>{{number | mask:
			'(999) 999-9999'}}</strong></div>
	<div class="verification-code" [ngClass]="{'error': codeError || nullCodeError}">
		<div class="alert alert-danger" role="alert" *ngIf="codeError">Invalid verification code.</div>
		<div class="alert alert-danger" role="alert" *ngIf="nullCodeError">Invalid verification code.</div>
		<!-- <input type="text" name="sms-pincode-input" id="sms-pincode-input" pin-code-input="code" (keyup)="keycount()"> -->
		<code-input [isNonDigitsCode]="false" [codeLength]="4" [code]="code" (codeChanged)="keycount($event)">
		</code-input>
	</div>
	<div class="didnt-get-code">Didn’t get a code? <a (click)="resendCode()">Resend code</a></div>
</div>
<div class="modal-footer text-right">
	<button type="button" class="btn cancel-button" (click)="closeThisDialog();cancelCode()">Cancel</button>
	<button (click)="confirmCode()" class="btn next-button">Continue</button>
</div>
