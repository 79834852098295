import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../../core/services/user.service';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { FileSizeExceedAlertComponent } from '../file-size-exceed-alert/file-size-exceed-alert.component';
import { ProfileDataService } from '../../../core/services/profile-data.service';

@Component({
	selector: 'add-resume-attachment-modal',
	templateUrl: './add-resume-attachment-modal.component.html',
	styleUrls: ['./add-resume-attachment-modal.component.scss']
})
export class AddResumeAttachmentModalComponent implements OnInit {

	private ssnUrl = environment.urls.ssnUrl_Obj;

	@Input() resumeName: string = '';
	@Output() saveResume: EventEmitter<any> = new EventEmitter<any>();
	@Output() deleteResume: EventEmitter<any> = new EventEmitter<any>();


	public enableUploadInEdit: boolean = false;
	public uploadAttachment: boolean = true;
	public disableSave: boolean = true;
	public uploading: boolean = false;
	public showUploaded: boolean = false;
	public enableSave: boolean = false;
	public enableDelete: boolean = false;
	public hideSaveButton: boolean = false;
	public editInProgress: boolean = false;


	public fileName: string = '';
	public fileData: any;

	constructor(private element: ElementRef,
		public modal: NgbActiveModal,
		private userService: UserService,
		private cookieService: CookieService,
		private modalService: NgbModal,
		private profileDataService: ProfileDataService) { }

	ngOnInit() {
		this.fileName = this.resumeName;
	}

	previewAttachmentView(file: any) {
		console.log(file)

		if (file) {
			if (file.size <= 2000000) {
				if (file.type !== 'image/gif' || file.type !== 'image/jpeg') {
					this.uploading = true;
					this.uploadAttachment = false;
					file.uploadUrl = `${this.ssnUrl}profile/resume/upload`;
					this.userService.uploadAttachment(file).subscribe((response: any) => {
						if (response.Code === 200) {
							this.fileData = response.Data;
							this.fileName = this.cookieService.get('username');
							this.uploading = false;
							this.showUploaded = true;
							this.disableSave = false;
							this.enableSave = true;
							// Check if user update a file after click the delete
							if (this.enableDelete = true) {
								this.enableDelete = false;
							}
						} else {
							this.uploadAttachment = true;
							this.uploading = false;
						}
					})
				}
			} else {
				this.fileSizeExceed();
				this.element.nativeElement.querySelector('input[type="file"]').value = null;
			}
		}
	}

	private fileSizeExceed() {
		let modal = this.modalService.open(FileSizeExceedAlertComponent, { windowClass: 'alert-popup-common', backdrop: 'static', keyboard: false });
		modal.componentInstance.msg = 'Oops! Your File Size cannot Exceed 2MB.';
	}

	// Clear Attachment in initail upload
	clearAttachment() {
		this.uploadAttachment = true;
		this.showUploaded = false;
		this.disableSave = true;
	}

	// Save attachment
	save() {
		// start uploading
		let obj = this.fileData
		let objToUpload = {
			resume: {
				attachment_type: this.fileData.attachment_type,
				attachment_url: this.fileData.attachment_url,
				file_name: this.fileData.file_name,
				co_guid: ''
			}
		};
		this.userService.addComponent(objToUpload).subscribe((response: any) => {
			if (response.Code === 200) {
				this.saveResume.emit({ data: this.fileData });
				this.profileDataService.setEditResumeId(response.Data.Resume.Id);
			} else {
				console.log("error");
			}
		})
		this.modal.dismiss();
	}

	// Update attachment
	update() {
		// start uploading
		let obj = this.fileData
		let objToUpload = {
			UpdateRecords: [
				{
					Type: 'RESUME',
					Data: {
						Id: this.profileDataService.getEditResumeId(),
						attachment_type: obj.attachment_type,
						attachment_url: obj.attachment_url,
						file_name: obj.file_name,
						co_guid: ''
					}
				}
			]
		};

		this.userService.profileUpdate(objToUpload).subscribe((response: any) => {
			if (response.Code === 200) {
				this.saveResume.emit({ data: this.fileData });
			}
		})

		this.modal.dismiss();
	}

	// Delete Attachment
	delete() {
		// console.log(this.profileDataService.getEditResumeId())
		let objToDelete = {
			DeleteIds: [{
				Type: 'RESUME',
				Id: this.profileDataService.getEditResumeId()
			}]
		};

		this.userService.profileUpdate(objToDelete).subscribe((response: any) => {
			if (response.Code === 200) {
				this.deleteResume.emit({ data: null })
			}
		})
		this.modal.dismiss();
	}

	onFileChange(event: any) {
		if (event.target.files.length > 0) {
			this.previewAttachmentView(event.target.files[0]);
		}
	}

	closeThisDialog() {
		this.modal.dismiss();
	}

}
